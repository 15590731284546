import AddressFieldGroup from 'js/address/fields/field-group';
import BaseSaveForm from 'js/base_v2/save-form';
import DomainsField from 'js/common_v2/domains-field';
import ContactGroupingField from 'js/contacts/fields/contact-grouping-field';

export default function ContactGroupSaveForm(record, options, inherited) {
  BaseSaveForm.call(this, record, options);
  const parent = this.clone();

  /**
   * @prop {DomainsField}
   */
  this.domainsField = null;

  /**
   * @prop {AddressFieldGroup}
   */
  this.addressFieldGroup = null;

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      tmplEl: '#contact-company-save-form',
      entityName: 'contact_group',
      entityLabel: 'company',
      actionUrl: '/contacts/contact-group/save',
      contactGroupDefaultValues: {},
      domainsField: {},
      addressFieldGroup: {
        stateField: {
          select2: {
            placeholder: 'State',
          },
        },
        countryField: {
          select2: {
            placeholder: 'Country *',
          },
        },
      },
      contactGroupingField: {
        select2: {
          placeholder: 'Select a Contact Group',
        },
      },
    });
  };

  /**
   * @inheritDoc
   */
  this.processOptions = function() {
    parent.processOptions.call(this);

    let defaultValues = this.options.defaultRecord.extend({
      base_margin: 40,
      domains: this.options.defaultRecord.domains,
      is_customer: 0,
      is_vendor: 0,
    });

    if (!_.isEmpty(this.options.contactGroupDefaultValues)) {
      defaultValues = defaultValues.extend(
        this.options.contactGroupDefaultValues,
      );
    } else {
      defaultValues = defaultValues.extend({
        is_customer: 1,
      });
    }

    this.options.defaultRecord = defaultValues;

    if (_.isArray(this.options.defaultRecord.domains)) {
      this.options.domainsField = this.options.domainsField.extend({
        selectedRecords: this.options.defaultRecord.domains,
      });
    }

    return this;
  };

  /**
   * @inheritDoc
   */
  this.initFields = function() {
    // Create domains field
    this.domainsField = new DomainsField(
      $('.domainsFieldCt', this.formEl),
      $('.domainsFieldCt .domainsSelect', this.formEl),
      this.options.domainsField,
    ).create();

    // Create contact group grouping field
    this.contactGroupingField = new ContactGroupingField(
      $('.contactGroupingFieldCt', this.formEl),
      $('.contactGroupingFieldCt .contactGroupingSelect', this.formEl),
      this.options.contactGroupingField,
    ).create();

    // Create address field group
    this.addressFieldGroup = new AddressFieldGroup(
      $('.addressFieldGroupCt', this.formEl),
      this.options.addressFieldGroup,
    ).create();

    return this;
  };

  /**
   * @inheritDoc
   */
  this.validate = function() {
    if (false === parent.validate.call(this)) {
      return false;
    }

    const baseMarginInput = $(
      'input[name="contact_group[base_margin]"]',
      this.formEl,
    );

    const baseDiscountInput = $(
      'input[name="contact_group[base_discount]"]',
      this.formEl,
    );

    if (0 === baseMarginInput.length && 0 === baseDiscountInput.length) {
      return true;
    }

    const baseMargin = baseMarginInput.val();
    const baseDiscount = baseDiscountInput.val();

    if (!(baseMargin > 0) && !(baseDiscount > 0)) {
      this.notifyError(
        'You must enter either the base margin, or the base discount.',
      );

      return false;
    }

    return true;
  };

  /**
   * @inheritDoc
   */
  this.getConstraints = function() {
    return {
      'contact_group[name]': {
        required: {
          message: 'Company name cannot be empty.',
        },
      },
      'contact_group[company_address][name]': {
        required: {
          message: 'Location name cannot be empty.',
        },
      },
      'contact_group[company_address][zip]': {
        required: {
          message: this.translator.get('error_empty_address_zip'),
        },
      },
      'contact_group[company_address][country]': {
        required: {
          message: 'Country cannot be empty.',
        },
      },
    };
  };

  if (true !== inherited) {
    // Initialize
    this.init();
  }
}
