import BaseSelect2AutocompleteField from 'js/base_v2/select2-autocomplete-field';

/**
 * @extends BaseSelect2AutocompleteField
 *
 * @param {DOMElement} fieldCt
 * @param {DOMElement} selectEl
 * @param {object}     options
 */
export default function AddressField(fieldCt, selectEl, options) {
    BaseSelect2AutocompleteField.call(this, fieldCt, selectEl, options);
    var parent = this.clone();
    var self = this;

    /**
     * @inheritDoc
     */
    this.initDefaults = function() {
        parent.initDefaults.call(this);

        this.defaultOptions = $.extend(true, {}, this.defaultOptions, {
            entitiesName: 'addresses',
            defaultActionType: 'select',
            baseEntityName: undefined,
            addressEntityName: 'address',
            fieldLabel: 'address',
            selectClassName: '',
            selectTmplId: 'address-field-select-template',
            selectTmplParams: {},
            createInlineTmplId: 'address-field-create-inline-template',
            createInlineTmplParams: {
                record: {}
            },
            select2: {
                placeholder: 'Select a Location',
                allowClear: false
            }
        });

        return this;
    };

    /**
     * @inheritDoc
     */
    this.processOptions = function() {
        parent.processOptions.call(this);

        // Set up "select" template parameters
        this.options.selectTmplParams = $.extend(
            true,
            {},
            this.options.selectTmplParams,
            {
                baseEntityName: this.options.baseEntityName,
                addressEntityName: this.options.addressEntityName,
                fieldLabel: this.options.fieldLabel,
                selectClassName: this.options.selectClassName
            }
        );

        // Set up "create inline" template parameters
        this.options.createInlineTmplParams = $.extend(
            true,
            {},
            this.options.createInlineTmplParams,
            {
                baseEntityName: this.options.baseEntityName,
                addressEntityName: this.options.addressEntityName,
                fieldLabel: this.options.fieldLabel
            }
        );

        return this;
    };

    /**
     * @inheritDoc
     */
    this.create = function() {
        parent.create.call(this);

        if (0 === this.selectEl.length) {
            this.switchTo(this.options.defaultActionType);
            return this;
        }

        return this;
    };

    /**
     * @inheritDoc
     */
    this.registerEventListeners = function() {
        parent.registerEventListeners.call(this);

        var actionTypes = ['select', 'createInline'];

        for (var i in actionTypes) {
            (function(actionType) {
                self.fieldCt.on('click', '.switchTo' + actionType.capitalize(), function(e) {
                    e.preventDefault();
                    self.switchTo(actionType);
                });
            })(actionTypes[i]);
        }

        return this;
    };

    /**
     * @inheritDoc
     */
    this.getRepoLabel = function(repo) {
        return Renderer.Address(repo, ', ');
    };

    /**
     * @inheritDoc
     */
    this.getRecordLabel = function(record) {
        return Renderer.Address(record, ', ') || record.text || record.label;
    };

    this.init();
};
