import AddressHelper from 'js/address/helper';
import BaseSaveForm from 'js/base_v2/save-form';
import ContactGroupField from 'js/contacts/contact-group-field';
import ContactGroupingField from 'js/contacts/fields/contact-grouping-field';
import AddressField from 'js/address/fields/field';
import Ajax from 'js/components/ajax';

const staticSelf = ContactSaveForm;

/**
 * @const
 */
staticSelf.STATE_DEFAULT = 'default';

/**
 * @const
 */
staticSelf.STATE_EMAIL_INPUT = 'email_input';

/**
 * Contact Save Form.
 *
 * @class
 * @extends BaseSaveForm
 *
 * @param {object|object[]} [records]
 * @param {object}          [options]
 */
function ContactSaveForm(records, options) {
  BaseSaveForm.call(this, records, options);
  const parent = this.clone();
  const self = this;

  /**
   * @prop {object}
   */
  this.contactGroup = null;

  /**
   * @prop {boolean}
   */
  this.manageVisitorsAllowed = null;

  /**
   * @prop {ContactGroupField}
   */
  this.contactGroupField = null;

  /**
   * @prop {ContactGroupingField}
   */
  this.contactGroupingField = null;

  /**
   * @prop {AddressField}
   */
  this.billToField = null;

  /**
   * @prop {AddressField}
   */
  this.soldToField = null;

  /**
   * @prop {AddressField}
   */
  this.shipToField = null;

  /**
   * @prop {string[]}
   */
  this.addressFieldNames = [
    'billToField',
    'soldToField',
    'shipToField',
  ];

  /**
   * @prop {object}
   */
  this.addressFieldNameToRecordFieldMap = {
    billToField: 'billing_address',
    soldToField: 'selling_address',
    shipToField: 'shipping_address',
  };

  /**
   * @prop {string[]}
   */
  this.fieldNames = [].concat(this.addressFieldNames, [
    'contactGroupField',
  ]);

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      entityName: 'contact',
      entityLabel: 'contact',
      tmplEl: '#contact-save-form',
      actionUrl: '/contacts/contact/save',
      contactGroupFieldClass: ContactGroupField,
      contactGroupField: {
        select2: {
          placeholder: 'Select a Company',
        },
        onChange() {
          self.onContactGroupFieldChange();
        },
      },
      contactGroupingFieldClass: ContactGroupingField,
      contactGroupingField: {
        baseEntityName: 'contact',
        addressEntityName: 'contact_grouping',
        fieldLabel: 'Contact Group',
        selectClassName: 'groupingSelect',
        select2: {
          placeholder: 'Select a Contact Group',
        },
      },
      billToFieldClass: AddressField,
      billToField: {
        baseEntityName: 'contact',
        addressEntityName: 'billing_address',
        fieldLabel: 'Bill to *',
        selectClassName: 'billToSelect',
        autocompleteParams: {
          attached_to: 'contact_group',
        },
        select2: {
          ajax: {
            url: '/addresses/index/list/',
          },
        },
      },
      soldToFieldClass: AddressField,
      soldToField: {
        baseEntityName: 'contact',
        addressEntityName: 'selling_address',
        fieldLabel: 'Sold to *',
        selectClassName: 'soldToSelect',
        autocompleteParams: {
          attached_to: 'contact_group',
        },
        select2: {
          ajax: {
            url: '/addresses/index/list/',
          },
        },
      },
      shipToFieldClass: AddressField,
      shipToField: {
        baseEntityName: 'contact',
        addressEntityName: 'shipping_address',
        fieldLabel: 'Ship to *',
        selectClassName: 'shipToSelect',
        autocompleteParams: {
          attached_to: 'contact_group',
        },
        select2: {
          ajax: {
            url: '/addresses/index/list/',
          },
        },
      },
      onCheckEmailSuccess: undefined,
      onContactGroupFieldChange: undefined,
    });
  };

  /**
   * @inheritDoc
   */
  this.create = function() {
    parent.create.call(this);

    this.switchFormPart('billing', 'default');
    this.switchFormPart('selling', 'default');
    this.switchFormPart('shipping', 'default');

    const contactGroup = this.getContactGroup();

    if (_.isObject(contactGroup) || this.isContactGroupFieldShown()) {
      this.showContactFormPart('instant');
    }

    return this;
  };

  /**
   * @inheritDoc
   */
  this.processOptions = function() {
    parent.processOptions.call(this);

    const contact = this.getRecord();
    let contactGroup = null;

    if (_.isObject(contact)) {
      this.processContactOptions(contact);

      contactGroup = contact.group;
    }

    contactGroup = _.isObject(this.contactGroup) ?
      this.contactGroup :
      contactGroup;

    if (_.isObject(contactGroup)) {
      this.processContactGroupOptions(contactGroup);
    }

    return this;
  };

  /**
   * Process contact options.
   *
   * @param  {object}          contact
   * @return {ContactSaveForm}
   */
  this.processContactOptions = function(contact) {
    if (contact.contact_grouping_id > 0) {
      // Set contact grouping field selected record
      this.options.contactGroupingField.selectedRecords = [{
        id: contact.contact_grouping_id,
        name: contact.contact_grouping_name,
      }];
    }

    _.each(this.addressFieldNames, function(addressFieldName) {
      const contactAddress = contact[
        this.addressFieldNameToRecordFieldMap[addressFieldName]
      ];

      if (_.isObject(contactAddress) &&
                _.isEmpty(this.options[addressFieldName].selectedRecords)
      ) {
        this.options[addressFieldName].selectedRecords = [contactAddress];
      }
    }, this);

    return this;
  };

  /**
   * Process contact group options.
   *
   * @param  {object}          contactGroup
   * @return {ContactSaveForm}
   */
  this.processContactGroupOptions = function(contactGroup) {
    // Set contact group field selected record
    this.options.contactGroupField.selectedRecords = [contactGroup];

    _.each(this.addressFieldNames, function(addressFieldName) {
      this.options[addressFieldName].autocompleteParams.contact_group =
        contactGroup.id;
    }, this);

    return this;
  };

  /**
   * @inheritDoc
   */
  this.prepareTmplParams = function() {
    parent.prepareTmplParams.call(this);

    return this.extendOptions({
      tmplParams: {
        manageVisitorsAllowed: this.manageVisitorsAllowed,
      },
    });
  };

  /**
   * @inheritDoc
   */
  this.initFields = function() {
    parent.initFields.call(this);

    this
      .createField('contactGroup')
      .createField('contactGrouping')
      .createField('billTo')
      .createField('soldTo')
      .createField('shipTo');

    return this;
  };

  /**
   * @inheritDoc
   */
  this.registerEventListeners = function() {
    parent.registerEventListeners.call(this);

    this.formEl.on('click', '.checkEmailBtn', (event) => {
      self.onCheckEmailBtnClick(event);
    });

    return this;
  };

  /**
   * Get input email.
   *
   * @return {string}
   */
  this.getInputEmail = function() {
    return $('.emailBlock input[name="contact[create_email]"]', this.formEl)
      .val();
  };

  /**
   * @inheritDoc
   */
  this.getConstraints = function() {
    return {
      'contact[group_id]': {
        skipIfHidden: true,
        required: {
          message: 'Company cannot be empty.',
        },
      },
      'contact[first_name]': {
        required: {
          message: 'First name cannot be empty.',
        },
      },
      'contact[last_name]': {
        required: {
          message: 'Last name cannot be empty.',
        },
      },
      'contact[email]': {
        required: {
          message: 'Email cannot be empty.',
        },
      },
      'contact[billing_address_id]': {
        skipIfHidden: true,
        required: {
          message: 'Bill to address cannot be empty.',
        },
      },
      'contact[selling_address_id]': {
        skipIfHidden: true,
        required: {
          message: 'Sold to address cannot be empty.',
        },
      },
      'contact[shipping_address_id]': {
        skipIfHidden: true,
        required: {
          message: 'Ship to address cannot be empty.',
        },
      },
    };
  };

  /**
   * Get contact group.
   *
   * @return {?object}
   */
  this.getContactGroup = function() {
    if (_.isObject(this.contactGroup)) {
      return this.contactGroup;
    }

    const record = this.getRecord();

    if (Object.get(record, 'group.id') > 0) {
      return record.group;
    }

    return null;
  };

  /**
   * Set contact group.
   *
   * @param  {object}          contactGroup
   * @return {ContactSaveForm}
   */
  this.setContactGroup = function(contactGroup) {
    if (!this.isContactGroupFieldShown()) {
      this.contactGroup = contactGroup;
    }

    this.options.contactGroupField.selectedRecords = [contactGroup];

    const companyAddress = this.getCompanyAddressFromContactGroup(contactGroup);

    _.each(this.addressFieldNames, function(addressFieldName) {
      this.options[addressFieldName].autocompleteParams.contact_group =
        contactGroup.id;
      this.options[addressFieldName].selectedRecords =
        _.filter([companyAddress]);
    }, this);

    return this;
  };

  /**
   * Unset contact group.
   *
   * @return {ContactSaveForm}
   */
  this.unsetContactGroup = function() {
    this.contactGroup = null;
    this.options.contactGroupField.selectedRecords = [];

    _.each(this.addressFieldNames, function(addressFieldName) {
      this.options[addressFieldName].selectedRecords = [];
    }, this);

    return this;
  };

  /**
   * Get company address from contact group.
   *
   * @param  {object}  contactGroup
   * @return {?object}
   */
  this.getCompanyAddressFromContactGroup = function(contactGroup) {
    if (_.isObject(contactGroup.company_address) &&
            contactGroup.company_address.id > 0
    ) {
      return contactGroup.company_address;
    }

    const companyAddress = AddressHelper.createAddressFromRecord(
      contactGroup,
      'company_address',
    );

    return (_.isObject(companyAddress) && companyAddress.id > 0) ?
      companyAddress :
      null;
  };

  /**
   * Slide the bottom part of the form into the view.
   *
   * @param  {string}          effect slide|instant
   * @return {ContactSaveForm}
   */
  this.showContactFormPart = function(effect) {
    const thisModal = this.formEl.closest('.modal');

    thisModal.addClass('modal_new_company');

    if ('slide' === effect) {
      thisModal.find('.emailBlock').slideUp();
      thisModal.find('.jsInfoDefaultCompany').slideDown();
    } else {
      thisModal.find('.emailBlock').hide();
      thisModal.find('.jsInfoDefaultCompany').show();
    }

    return this;
  };

  /**
   * @inheritDoc
   */
  this.onSubmit = function(validate, event) {
    if (staticSelf.STATE_EMAIL_INPUT === this.getFormState()) {
      if (_.isObject(event)) {
        event.preventDefault();
      }

      this.formEl.find('.checkEmailBtn').trigger('click');
    } else {
      parent.onSubmit.call(this, validate, event);
    }
  };

  /**
   * Get form state.
   *
   * @return {boolean}
   */
  this.getFormState = function() {
    if ($('.emailBlock', this.formEl).is(':visible')) {
      return staticSelf.STATE_EMAIL_INPUT;
    }

    return staticSelf.STATE_DEFAULT;
  };

  /**
   * Contact group field change event handler.
   *
   * @return {boolean|undefined}
   */
  this.onContactGroupFieldChange = function() {
    const contactGroup = this.contactGroupField.getRecord();

    if (_.isFunction(this.options.onContactGroupFieldChange) &&
      false === this.options.onContactGroupFieldChange(contactGroup)
    ) {
      return false;
    }

    const addressFieldAutocompleteParams = {
      contact_group: _.isObject(contactGroup) ? contactGroup.id : null,
    };

    const companyAddress = this.getCompanyAddressFromContactGroup(contactGroup);

    _.each(this.addressFieldNames, function(addressFieldName) {
      this.options[addressFieldName].autocompleteParams = Object.extend(
        this.options[addressFieldName].autocompleteParams,
        addressFieldAutocompleteParams,
      );

      this[addressFieldName].options.autocompleteParams = Object.extend(
        this[addressFieldName].options.autocompleteParams,
        addressFieldAutocompleteParams,
      );

      this[addressFieldName].setRecord(companyAddress);
    }, this);

    return undefined;
  };

  /**
   * Check email button click event handler.
   *
   * @param  {Event}           event
   * @return {ContactSaveForm}
   */
  this.onCheckEmailBtnClick = function(event) {
    event.preventDefault();

    this.checkEmail($(
      '.emailBlock input[name="contact[create_email]"]',
      this.formEl,
    ).val());

    return this;
  };

  /**
   * Check email using an AJAX request.
   *
   * @param  {string}  email
   * @return {boolean}
   */
  this.checkEmail = function(email) {
    if (!email) {
      this.notifyValidationError(
        `${this.options.entityName}[create_email]`,
        'Email cannot be empty.',
      );

      return false;
    }

    if (email.length > 255) {
      this.notifier.notifyError(this.translator.get(
        'error_email_too_long',
      ));

      return false;
    }

    let data = Object.createFromKey(
      this.options.entityName,
      { email },
    );

    if (_.isObject(this.record)) {
      data = data.extend(Object.createFromKey(
        this.options.entityName,
        { id: this.record.id },
      ));
    }

    this.notifier.notifyLoading('Checking email...');

    Ajax.get(
      '/contacts/details',
      data,
      (response) => {
        self.onCheckEmailSuccess(response);
      },
      (response, error) => {
        self.onCheckEmailError(response, error);
      },
    );

    return true;
  };

  /**
   * Check email success event handler.
   *
   * @param  {object}            response
   * @return {boolean|undefined}
   */
  this.onCheckEmailSuccess = function(response) {
    if (_.isFunction(this.options.onCheckEmailSuccess) &&
            false === this.options.onCheckEmailSuccess(response)
    ) {
      return false;
    }

    this.notifier.clear();

    return undefined;
  };

  /**
   * Check email error event handler.
   *
   * @param {object} response
   * @param {string} error
   */
  this.onCheckEmailError = function(response, error) {
    const errorMessage = `An error occurred while checking email: ${error}`;
    this.notifier.notifyError(errorMessage);
  };

  /**
   * @inheritDoc
   */
  this.processFormData = function(formData) {
    let processedFormData = parent.processFormData.call(this, formData);

    processedFormData = this.processContactGroupFormData(processedFormData);

    return processedFormData;
  };

  /**
   * Process contact group form data.
   *
   * @param  {object} formData
   * @return {object}
   */
  this.processContactGroupFormData = function(formData) {
    if (formData[this.options.entityName].group_id > 0) {
      // Nothing to process
      return formData;
    }

    const contactGroup = this.getContactGroup();

    return formData.extend(Object.createFromKey(
      this.options.entityName,
      { group_id: _.isObject(contactGroup) ? contactGroup.id : null },
    ));
  };

  /**
   * @inheritDoc
   */
  this.saveState = function() {
    if (staticSelf.STATE_EMAIL_INPUT === this.getFormState()) {
      // Saving state not needed when only email field is displayed
      return this;
    }

    parent.saveState.call(this);

    this.options.contactGroupField.selectedRecords = _.filter([
      this.contactGroupField.getRecord(),
    ]);

    this.options.contactGroupingField.selectedRecords = _.filter([
      this.contactGroupingField.getRecord(),
    ]);

    _.each(this.addressFieldNames, function(addressFieldName) {
      this.options[addressFieldName].selectedRecords = _.filter([
        this[addressFieldName].getRecord(),
      ]);
    }, this);

    return this;
  };

  /**
   * Check whether contact group field is visible.
   *
   * @return boolean
   */
  this.isContactGroupFieldShown = function() {
    return !_.contains(this.options.hiddenFields, 'contact[group_id]');
  };

  /**
   * @inheritDoc
   */
  this.getSubtitle = function() {
    const contactGroup = this.getContactGroup();

    if (!_.isObject(contactGroup) || this.isContactGroupFieldShown()) {
      // No subtitle
      return '';
    }

    return contactGroup.name;
  };

  /**
   * Get address field names.
   *
   * @return {string[]}
   */
  this.getAddressFieldNames = function() {
    return this.addressFieldNames;
  };

  /**
   * @inheritDoc
   */
  this.resetState = function(opts = {}) {
    parent.resetState.call(this, opts);

    return this
      .unsetContactGroup()
      .extendOptions({
        hiddenFields: [
          'contact[group_id]',
        ],
      });
  };

  // Initialize
  this.init();
}

export default ContactSaveForm;
