import BaseSelect2AutocompleteField from 'js/base_v2/select2-autocomplete-field';
/**
 * Address Field.
 *
 * @class
 * @extends BaseSelect2AutocompleteField
 *
 * @param {DOMElement} fieldCt
 * @param {DOMElement} selectEl
 * @param {object}     options
 */
function AddressField(fieldCt, selectEl, options) {
    BaseSelect2AutocompleteField.call(this, fieldCt, selectEl, options);
    var parent = this.clone();
    var self = this;

    /**
     * @inheritDoc
     */
    this.initDefaults = function() {
        parent.initDefaults.call(this);

        this.extendDefaultOptions({
            entitiesName: 'addresses',
            select2: {
                placeholder: 'Select a Location',
                allowClear: true,
                ajax: {
                    url: '/addresses/address/autocomplete'
                }
            }
        });

        return this;
    };

    // Initialize
    this.init();
}

export default AddressField;
