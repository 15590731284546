import CountryField from 'js/address/fields/country-field';
import StateField from 'js/address/fields/state-field';
import BaseFieldGroup from 'js/base_v2/field-group';

const staticSelf = AddressFieldGroup;

/**
 * @const
 */
staticSelf.COUNTRIES_WITH_STATES = [
  'US',
  'CA',
];

/**
 * Address Field Group.
 *
 * @class
 * @extends BaseFieldGroup
 *
 * @param {DOMElement} fieldGroupCt
 * @param {object}     options
 */
function AddressFieldGroup(fieldGroupCt, options) {
  BaseFieldGroup.call(this, fieldGroupCt, options);
  const parent = this.clone();
  const self = this;

  /**
   * @inheritDoc
   */
  this.fieldParams = [{
    name: 'countryField',
    className: CountryField,
    fieldEl: '.countrySelect',
    fieldCt: '.countryFieldCt',
  }, {
    name: 'stateField',
    className: StateField,
    fieldEl: '.stateSelect',
    fieldCt: '.stateFieldCt',
  }];

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      allowEmptyState: false,
      stateField: {
        select2: {
          placeholder: '',
        },
        onChange() {
          self.onStateFieldChange();
        },
      },
      countryField: {
        select2: {
          placeholder: '',
        },
        onChange() {
          self.onCountryFieldChange();
        },
      },
    });
  };

  /**
   * @inheritDoc
   */
  this.initField = function(params) {
    if ('stateField' !== params.name) {
      return parent.initField.call(this, params);
    }

    const country = this.fields.countryField.options.selectedRecords[0];

    if (staticSelf.COUNTRIES_WITH_STATES.includes(country.abbreviation)) {
      this.disableStateInputField();

      $('.stateSelectCt', this.fieldGroupCt).addClass('active-state-field');

      return parent.initField.call(this, params);
    }

    parent.initField.call(this, params);

    $('.stateInputCt', this.fieldGroupCt).addClass('active-state-field');

    $('.stateSelect').val(null).trigger('change');

    this.fields.stateField.options.selectedRecords = [];

    return this;
  };

  /**
   * State field change event handler.
   *
   * @return {AddressFieldGroup}
   */
  this.onStateFieldChange = function() {
    const selectedState = this.fields.stateField.getRecord();

    if (_.isObject(selectedState)) {
      this.fields.countryField.setRecord({
        abbreviation: selectedState.country_abbreviation,
        name: selectedState.country_name,
      });
    }

    return this;
  };

  /**
   * Country field change event handler.
   *
   * @return {AddressFieldGroup}
   */
  this.onCountryFieldChange = function() {
    this.disableStateInputField();

    const selectedCountry = this.fields.countryField.getRecord();

    const statesAvailable = staticSelf.COUNTRIES_WITH_STATES.includes(
      selectedCountry.abbreviation,
    );

    $('.stateSelectCt', this.fieldGroupCt)
      .toggleClass('active-state-field', statesAvailable);

    $('.stateInputCt', this.fieldGroupCt)
      .toggleClass('active-state-field', !statesAvailable);

    if (!statesAvailable) {
      $('.stateSelect').val(null).trigger('change');

      const placeholder = this.options.allowEmptyState ?
        this.translator.getTitle('label_state') :
        this.translator.getTitle('label_state_required');

      $('.stateInputCt input', this.fieldGroupCt).attr(
        'placeholder',
        placeholder,
      );

      $('.stateInputCt input', this.fieldGroupCt).attr('disabled', false);

      return this;
    }

    const selectedState = this.fields.stateField.getRecord();

    if (_.isObject(selectedState) &&
      selectedCountry.abbreviation !== selectedState.country_abbreviation
    ) {
      $('.stateSelect').val(null).trigger('change');
    }

    this.fields.stateField.extendOptions({
      autocompleteParams: {
        filters: {
          country_abbreviation: `"${selectedCountry.abbreviation}"`,
        },
      },
    });

    return this;
  };

  /**
   * Set state input field value.
   *
   * @param  {string}            state
   * @return {AddressFieldGroup}
   */
  this.setStateInputFieldValue = function(state) {
    $('.stateInputCt input', this.fieldGroupCt).val(state);
  };

  /**
   * Disable state input field.
   *
   * @return {AddressFieldGroup}
   */
  this.disableStateInputField = function() {
    $('.stateInputCt input', this.fieldGroupCt).val(null);

    $('.stateInputCt input', this.fieldGroupCt).attr('disabled', true);

    return this;
  };

  // Initialize
  this.init();
}

export default AddressFieldGroup;
