import BaseSelect2AutocompleteField from 'js/base_v2/select2-autocomplete-field';

/**
 * Contact Group Field.
 *
 * @class
 * @extends BaseSelect2AutocompleteField
 *
 * @param {DOMElement} fieldCt
 * @param {DOMElement} selectEl
 * @param {object}     [options]
 */
function ContactGroupField(fieldCt, selectEl, options) {
    BaseSelect2AutocompleteField.call(this, fieldCt, selectEl, options);
    const parent = this.clone();
    const self = this;

    /**
     * @inheritDoc
     */
    this.initDefaults = function() {
        parent.initDefaults.call(this);

        return this.extendDefaultOptions({
            entitiesName: 'contact_groups',
            select2: {
                placeholder: '',
                allowClear: false,
                ajax: {
                    url: '/contacts/contact-group/autocomplete'
                }
            }
        });
    };

    // Initialize
    this.init();
}

export default ContactGroupField;
