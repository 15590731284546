import BaseSelect2AutocompleteField from 'js/base_v2/select2-autocomplete-field';
/**
 * State Field.
 *
 * @class
 * @extends BaseSelect2AutocompleteField
 *
 * @param {DOMElement} fieldCt
 * @param {DOMElement} fieldEl
 * @param {object}     options
 */
function StateField(fieldCt, fieldEl, options) {
    BaseSelect2AutocompleteField.call(this, fieldCt, fieldEl, options);
    var parent = this.clone();
    var self = this;

    /**
     * @inheritDoc
     */
    this.initDefaults = function() {
        parent.initDefaults.call(this);

        return this.extendDefaultOptions({
            entitiesName: 'states',
            autocompleteParams: {
                filters: {
                    country_abbreviation: '"US"'
                }
            },
            select2: {
                placeholder: '',
                allowClear: false,
                ajax: {
                    url: '/addresses/state/autocomplete'
                }
            }
        });
    };

    // Initialize
    this.init();
}

export default StateField;
