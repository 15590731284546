import BaseComponent from 'js/base_v3/components/component';
/**
 * Base Service.
 *
 * @class
 * @abstract
 * @extends BaseComponent
 *
 * @param {?object} options
 */
function BaseService(options) {
    BaseComponent.call(this, options);
    var parent = this.clone();
    var self = this;
}

export default BaseService;
