import AddressField from 'js/address/field';
import AddressFieldGroup from 'js/address/fields/field-group';
import Address from 'js/address/models/address';
import BaseSaveForm from 'js/base_v2/save-form';
import ValidationError from 'js/errors/validation-error';
import OrderCustomerNumberField from 'js/order/fields/customer-number-field';

/**
 * Address Save Form.
 *
 * @class
 * @extends BaseSaveForm
 *
 * @param       {?object} record
 * @param       {object}  [options]
 */
function AddressSaveForm(record, options) {
  BaseSaveForm.call(this, record, options);
  const parent = this.clone();

  /**
   * @prop {AddressField}
   */
  this.field = null;

  /**
   * @prop {AddressFieldGroup}
   */
  this.fieldGroup = null;

  /**
   * @prop {OrderCustomerNumberField}
   */
  this.orderAccountsReceivableId = null;

  /**
   * @prop {OrderCustomerNumberField}
   */
  this.orderCustomerNumberField = null;

  /**
   * @prop {OrderCustomerNumberField}
   */
  this.orderAccountsPayableId = null;

  /**
   * @inheritDoc
   */
  this.initDefaults = function () {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      entityName: 'address',
      entityLabel: 'location',
      tmplEl: '#address-save-form',
      actionUrl: '/addresses/index/save',
      field: {
        defaultActionType: 'createInline',
        baseEntityName: null,
        addressEntityName: 'address',
        fieldLabel: 'address',
        createInlineTmplParams: {},
      },
      hiddenFields: [
        'address[external_customer_number]',
        'address[accounts_receivable_id]',
        'address[accounts_payable_id]',
      ],
      addressFieldGroup: {
        stateField: {},
        countryField: {},
      },
      validate: (notify, formData) => this.validateFormData(formData),
    });
  };

  /**
   * @inheritDoc
   */
  this.processOptions = function () {
    parent.processOptions.call(this);

    let formRecord = this.getRecord();
    formRecord = !_.isObject(formRecord) ?
      this.options.defaultRecord :
      formRecord;

    if (!_.isEmpty(formRecord)) {
      if (!(formRecord instanceof Address)) {
        formRecord = new Address(formRecord);
      }

      const addressFieldGroupOptions = this.processAddressFieldGroupOptions(
        formRecord,
      );

      if (!_.isEmpty(addressFieldGroupOptions)) {
        this.options.addressFieldGroup = this.options.addressFieldGroup.extend(
          addressFieldGroupOptions,
        );
      }
    }

    return this;
  };

  /**
   * @inheritDoc
   */
  this.initFields = function () {
    this.options.field.createInlineTmplParams = this.options.field
      .createInlineTmplParams.extend({
        record: this.getRecord() || this.options.defaultRecord,
      });

    // Create address field
    this.field = new AddressField(
      $('.addressFieldCt', this.formEl),
      $('.addressSelect', this.formEl),
      this.options.field,
    ).create();

    // Create address field group
    this.fieldGroup = new AddressFieldGroup(
      $('.addressFieldGroupCt', this.formEl),
      this.options.addressFieldGroup,
    ).create();

    /* eslint-disable-next-line max-len */
    if (!this.options.hiddenFields.includes('address[external_customer_number]')) {
      this.createOrderCustomerNumberField();
    }

    /* eslint-disable-next-line max-len */
    if (!this.options.hiddenFields.includes('address[accounts_receivable_id]')) {
      this.createOrderAccountsReceivableIdField();
    }

    /* eslint-disable-next-line max-len */
    if (!this.options.hiddenFields.includes('address[accounts_payable_id]')) {
      this.createOrderAccountsPayableIdField();
    }

    return this;
  };

  /**
   * Process address field group options.
   *
   * @param  {Address} address
   * @return {object}
   */
  this.processAddressFieldGroupOptions = function (address) {
    const state = address.getStateData();
    const country = address.getCountryData();

    let addressFieldGroupOptions = {};

    if (!_.isNull(country) && !_.isNull(country.id)) {
      addressFieldGroupOptions = addressFieldGroupOptions.extend({
        countryField: {
          selectedRecords: [country],
        },
      });
    }

    if (!_.isNull(country) && !_.isNull(state) && !_.isNull(state.id)) {
      addressFieldGroupOptions = addressFieldGroupOptions.extend({
        stateField: {
          selectedRecords: [state],
          autocompleteParams: {
            filters: {
              country_abbreviation: `"${country.abbreviation}"`,
            },
          },
        },
      });
    }

    return addressFieldGroupOptions;
  };

  /**
   * Create order customer number field.
   */
  this.createOrderCustomerNumberField = function () {
    /* eslint-disable max-len */
    this.orderCustomerNumberField = new OrderCustomerNumberField(
      $('.externalCustomerNumberFieldCt', this.formEl),
      $('.externalCustomerNumberFieldCt .externalCustomerNumberField', this.formEl),
      this.options.orderCustomerNumberField,
    ).create();
    /* eslint-enable max-len */
  };

  this.createOrderAccountsReceivableIdField = function () {
    this.orderAccountsReceivableIdField = new OrderCustomerNumberField(
      $('.accountsReceivableIdFieldCt', this.formEl),
      $('.accountsReceivableIdFieldCt .accountsReceivableIdField', this.formEl),
      this.options.orderAccountsReceivableIdField,
    ).create();

    return this;
  };

  this.createOrderAccountsPayableIdField = function () {
    this.orderAccountsReceivableIdField = new OrderCustomerNumberField(
      $('.accountsPayableIdFieldCt', this.formEl),
      $('.accountsPayableIdFieldCt .accountsPayableIdField', this.formEl),
      this.options.orderAccountsPayableIdField,
    ).create();

    return this;
  };

  /**
   * @return {boolean}
   *
   * @throws {ValidationError}
   */
  this.validateFormData = function () {
    try {
      if (_.isObject(this.orderCustomerNumberField)) {
        this.orderCustomerNumberField.validate();
      }
    } catch (error) {
      if (error instanceof ValidationError) {
        this.notifyError(error);

        return false;
      }

      throw error;
    }

    return true;
  };

  /**
   * @inheritDoc
   */
  this.resetState = function (opts = {}) {
    parent.resetState.call(this, opts);
    this.options.field.createInlineTmplParams.record = null;

    return this;
  };

  /**
   * @inheritDoc
   */
  this.getConstraints = function () {
    return {
      'address[country]': {
        required: {
          message: 'Country cannot be empty.',
        },
      },
      'address[zip]': {
        required: {
          message: this.translator.get('error_empty_address_zip'),
        },
      },
      'address[name]': {
        required: {
          message: 'Location name cannot be empty.',
        },
      },
    };
  };

  // Initialize
  this.init();
}

export default AddressSaveForm;
