import BaseComponent from 'js/base_v2/component';

/**
 * Base Form Group.
 *
 * @class
 * @abstract
 * @extends BaseComponent
 *
 * @param {object} [records]
 * @param {object} [options]
 */
function BaseFormGroup(records, options) {
  BaseComponent.call(this, options);
  const parent = this.clone();
  const self = this;

  /**
   * @prop {object[]}
   */
  this.formParams = [];

  /**
   * @prop {object}
   */
  this.records = records || {};

  /**
   * @prop {object}
   */
  this.forms = {};

  /**
   * @prop {DOMElement}
   */
  this.triggerBtn = null;

  /**
   * @inheritDoc
   */
  this.init = function() {
    parent.init.call(this);

    return this
      .initFormParams()
      .initForms();
  };

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      activeFormName: undefined,
      afterSubmit: undefined,
    });
  };

  /**
   * Initialize form parameters.
   *
   * @return {BaseFormGroup}
   */
  this.initFormParams = function() {
    return this;
  };

  /**
   * Initialize forms.
   *
   * @return {BaseFormGroup}
   */
  this.initForms = function() {
    _.each(this.formParams, function(params) {
      switch (params.type) {
        case 'form_group':
          this.initFormGroup(params);
          break;
        case 'form':
        default:
          this.initForm(params);
      }
    }, this);

    return this;
  };

  /**
   * @inheritDoc
   */
  this.create = function() {
    this.getActiveForm().create();
    this.registerEventListeners();

    return this;
  };

  /**
   * Register event listeners.
   *
   * @return {BaseFormGroup}
   */
  this.registerEventListeners = function() {
    const activeForm = this.getActiveForm();

    if ('form' === activeForm.getFormType()) {
      const formEl = activeForm.getFormEl();

      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const formName in this.forms) {
        // eslint-disable-next-line no-shadow
        (function(formName) {
          formEl.on('click', `.switchTo-${formName}`, function(e) {
            e.preventDefault();
            self.onSwitchToFormBtnClick(formName, $(this));
          });

          /* eslint-disable max-len */
          $(document).off('click', `.select2-results .switchTo-${formName}`);
          $(document).on('click', `.select2-results .switchTo-${formName}`, function(e) {
            e.preventDefault();
            self.onSwitchToFormFromSelectBtnClick(formName, $(this));
          });
          /* eslint-enable max-len */
        }(formName));
      }

      $(document).on('click', '.switchFormPart', function(e) {
        e.preventDefault();

        const formName = $(this).data('formName');
        const formPartGroup = $(this).data('formPartGroup');
        const formPartName = $(this).data('formPartName');
        const select2Ct = $(this).closest('.select2-container');

        const select2Input = select2Ct.find('input[type="search"]');
        const form = self.forms[formName];

        form.switchFormPart(
          formPartGroup,
          formPartName,
          {
            value: select2Input.val(),
          },
        );

        select2Ct.detach();
      });
    }

    return this;
  };

  /**
   * Initialize form.
   *
   * @param  {object}        params
   * @return {BaseFormGroup}
   */
  this.initForm = function(params) {
    if (1 === params.className.length) {
      this.forms[params.name] = new params.className(
        this.options[params.name],
      );
    } else {
      this.forms[params.name] = new params.className(
        this.records[params.name] || null,
        this.options[params.name],
      );
    }

    return this;
  };

  /**
   * Initialize form group.
   *
   * @param  {object}        params
   * @return {BaseFormGroup}
   */
  this.initFormGroup = function(params) {
    this.forms[params.name] = new params.className(
      this.records[params.name] || [],
      this.options[params.name],
    );

    return this;
  };

  /**
   * Switch to form button click event handler.
   *
   * @param {string}     formName
   * @param {DOMElement} btn
   */
  this.onSwitchToFormBtnClick = function(formName, btn) {
    if (btn.attr('data-term')) {
      this.onSwitchToFormFromSelectBtnClick(formName, btn);
    } else {
      this.reload(formName, $(this));
    }
  };

  /**
   * Switch to form from select button click event handler.
   *
   * @param {string}     formName
   * @param {DOMElement} btn
   */
  this.onSwitchToFormFromSelectBtnClick = function(formName, btn) {
    const selectName = btn.attr('data-name');
    const select = _.isString(selectName) ?
      $(`select[name="${selectName}"]`) : null;

    let recordLabel = '';

    if (_.isObject(select) && select.length > 0 && select.prop('multiple')) {
      recordLabel = select.closest('.modal-form-group')
        .find('input.select2-search__field')
        .val();
    } else if (btn.attr('data-term')) {
      recordLabel = btn.attr('data-term');
    } else {
      recordLabel = btn.closest('.select2-dropdown')
        .find('input.select2-search__field')
        .val();
    }

    const record = {};
    const labelField = btn.attr('data-label-field') || 'name';

    if ('none' !== labelField) {
      record[labelField] = recordLabel;
    }

    this.reload(formName, btn, { record });
  };

  /**
   * Reload form group.
   *
   * @param  {string}        formName
   * @param  {DOMElement}    triggerBtn
   * @param  {object}        data
   * @return {BaseFormGroup}
   */
  this.reload = function(formName, triggerBtn, data) {
    // eslint-disable-next-line no-param-reassign
    data = data || {};

    this.getActiveForm()
      .saveState()
      .destroy();

    if (!_.isUndefined(formName)) {
      this.options.activeFormName = formName;
    }

    const activeForm = this.getActiveForm();

    if ('form' === activeForm.getFormType()) {
      if (!_.isUndefined(data.record)) {
        let record = activeForm.getRecord();

        if (!record) {
          activeForm.options.defaultRecord = Object.extend(
            activeForm.options.defaultRecord,
            data.record,
          );
        } else {
          record = Object.extend(record, data.record);
          activeForm.setRecord(record);
        }
      }

      activeForm.options.triggerBtn = triggerBtn;
    }

    this.triggerBtn = triggerBtn;

    return this.create();
  };

  /**
   * Destroy form group.
   *
   * @return {BaseFormGroup}
   */
  this.destroy = function() {
    this.getActiveForm().destroy();
    return this;
  };

  /**
   * Save state.
   *
   * @return {BaseFormGroup}
   */
  this.saveState = function() {
    this.getActiveForm().saveState();
    return this;
  };

  /**
   * Reset state.
   *
   * @param  {object}        [opts]
   * @return {BaseFormGroup}
   */
  this.resetState = function(opts = {}) {
    this.getActiveForm().resetState(opts);
    return this;
  };

  /**
   * Reset state for all forms.
   *
   * @param  {object}        [opts]
   * @return {BaseFormGroup}
   */
  this.resetStateForAllForms = function(opts = {}) {
    _.each(this.forms, (form) => form.resetState(opts));

    return this;
  };

  /**
   * Get active form DOM element.
   *
   * @return {DOMElement}
   */
  this.getFormEl = function() {
    return this.getActiveForm().getFormEl();
  };

  /**
   * Get active form.
   *
   * @return {BaseSaveForm}
   */
  this.getActiveForm = function() {
    return this.forms[this.options.activeFormName];
  };

  /**
   * Get active form name.
   *
   * @return {string}
   */
  this.getActiveFormName = function() {
    return this.options.activeFormName;
  };

  /**
   * Get form type.
   *
   * @return {string}
   */
  this.getFormType = function() {
    return 'form_group';
  };
}

export default BaseFormGroup;
