/* eslint-disable max-len */
import InputField from 'js/base_v2/fields/input-field';
import KeyHelper from 'js/helpers/key-helper';
import { customerNumberValidator } from 'js/order/validators/customer-number-validator';
/* eslint-enable max-len */

/**
 * Order Customer Number Field.
 *
 * @class
 * @extends InputField
 *
 * @param {DOMElement} fieldCt
 * @param {DOMElement} fieldEl
 * @param {object}     [options]
 */
function OrderCustomerNumberField(fieldCt, fieldEl, options) {
  InputField.call(this, fieldCt, fieldEl, options);
  const parent = this.clone();

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      onChange: undefined,
    });
  };

  /**
   * @inheritDoc
   */
  this.registerEventListeners = function () {
    parent.registerEventListeners.call(this);

    this.fieldEl.on('keypress', (event) => {
      if (KeyHelper.KEY_CODE_ENTER === event.which) {
        this.onChange();
      }
    });

    return this;
  };

  /**
   * @return {object}
   */
  this.validate = function() {
    return customerNumberValidator.validate(this.getValue());
  };

  /**
   * Change event handler.
   *
   * @return {boolean|undefined}
   */
  this.onChange = function() {
    if (_.isFunction(this.options.onChange) &&
      false === this.options.onChange()
    ) {
      return false;
    }

    return undefined;
  };

  // Initialize
  this.init();
}

export default OrderCustomerNumberField;
