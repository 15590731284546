import BaseSelect2AutocompleteField from 'js/base_v2/select2-autocomplete-field';
/**
 * Contact Grouping Field.
 *
 * @class
 * @extends BaseSelect2AutocompleteField
 *
 * @param {DOMElement} fieldCt
 * @param {DOMElement} selectEl
 * @param {object}     options
 * @param {bool}       inherited
 */
function ContactGroupingField(fieldCt, selectEl, options, inherited) {
    BaseSelect2AutocompleteField.call(this, fieldCt, selectEl, options);
    var parent = this.clone();
    var self = this;

    /**
     * @inheritDoc
     */
    this.initDefaults = function() {
        parent.initDefaults.call(this);

        return this.extendDefaultOptions({
            entitiesName: 'contact_groupings',
            select2: {
                placeholder: '',
                allowClear: true,
                ajax: {
                    url: '/corporate/contact-grouping/autocomplete'
                }
            }
        });
    };

    if (false !== inherited) {
        // Initialize
        this.init();
    }
}

export default ContactGroupingField;
