import ModalConfirmation from 'js/components_v2/modal-confirmation';
/**
 * Default Modal Confirmation.
 *
 * @class
 * @extends ModalConfirmation
 *
 * @param {object}  [options]
 * @param {boolean} [inherited]
 */
function DefaultModalConfirmation(options, inherited) {
    ModalConfirmation.call(this, options, true);
    var parent = this.clone();
    var self = this;

    /**
     * @inheritDoc
     */
    this.prepareTmplParams = function() {
        parent.prepareTmplParams.call(this);

        var confirmBtnClassName = this.getConfirmBtnClassName();

        return this.extendOptions({
            tmplParams: {
                confirmBtnClassName: confirmBtnClassName,
                cancelBtnClassName: 'btn-white'
            }
        });
    };

    /**
     * @inheritDoc
     */
    this.onConfirm = function(extraData) {
        if (false === parent.onConfirm.call(this, extraData)) {
            return false;
        }

        this.destroy();

        return undefined;
    };

    /**
     * Get confirm button class name.
     *
     * @return {string}
     */
    this.getConfirmBtnClassName = function() {
        switch (this.options.messageType) {
            case 'warning':
                return 'btn_danger';
            default:
                return '';
        }
    };

    if (true !== inherited) {
        // Initialize
        this.init();
    }
}

export default DefaultModalConfirmation;
