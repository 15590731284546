import BaseSelect2Field from 'js/base_v2/select2-field';

export default function DomainsField(fieldCt, fieldEl, options) {
    BaseSelect2Field.call(this, fieldCt, fieldEl, options);
    var parent = this.clone();
    var self = this;

    /**
     * @inheritDoc
     */
    this.initDefaults = function() {
        parent.initDefaults.call(this);

        this.defaultOptions = this.defaultOptions.extend({
            selectedRecords: [],
            select2: {
                selectOnClose: true,
                placeholder: '@domain.com',
                allowClear: false,
                tags: true,
                createTag: function(tag) {
                    return self.createTag(tag);
                }
            }
        });

        return this;
    };

    /**
     * @inheritDoc
     */
    this.initFields = function() {
        parent.initFields.call(this);
        $('.select2-search__field', this.fieldCt).css('width', 'auto');
        this.setValues(this.options.selectedRecords);

        return this;
    };

    /**
     * Get (selected) values.
     *
     * @return {string[]}
     */
    this.getValues = function() {
        return this.fieldEl.val();
    };

    /**
     * Set (selected) values.
     *
     * @param  {string[]}     records
     * @return {DomainsField}
     */
    this.setValues = function(records) {
        this.selectEl.html('');

        if (0 === records.length) {
            return;
        }

        _.each(records, function(r) {
            this.selectEl.append(
                '<option value="' + r + '" selected>@' + r + '</option>'
            );
        }, this);

        return this;
    };

    /**
     * @inheritDoc
     */
    this.onOpen = function(e) {
        parent.onOpen.call(this);

        $('.select2-dropdown').hide();

        $('.select2-selection', this.fieldCt)
            .css('border-bottom-left-radius', '4px')
            .css('border-bottom-right-radius', '4px');
    };

    /**
     * Create tag (select2).
     *
     * @param  {object} tag
     * @return {object}
     */
    this.createTag = function(tag) {
        var term = tag.term;
        var index = term.indexOf('@');

        if (-1 !== index) {
            term = term.substr(index + 1);
            term = term.replace(/@/g, '');
        }

        return {
            id: term,
            text: '@' + term,
            tag: true
        };
    };

    // Initialize
    this.init();
};
