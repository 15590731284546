var EmailHelper = {};

/**
 * Get email domain.
 *
 * @param  {string}      email
 * @return {string|null}
 */
EmailHelper.getDomain = function(email) {
    var emailDomain = email.split('@')[1];

    if ('undefined' === typeof emailDomain) {
        return null;
    }

    return emailDomain;
};

export default EmailHelper;
