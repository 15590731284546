import AddressHelper            from 'js/address/helper';
import AddressSaveForm          from 'js/address/save-form';
import BaseFormGroup            from 'js/base_v2/form-group';
import Renderer                 from 'js/components/renderer';
import DefaultModalConfirmation from 'js/components_v2/default-modal-confirmation';
import ContactGroupSaveForm     from 'js/contacts/contact-group-save-form';
import ContactSaveForm          from 'js/contacts/contact-save-form';
import EmailHelper              from 'js/helpers/email-helper';

/**
 * Contact Form Group.
 *
 * @class
 * @extends BaseFormGroup
 *
 * @param {object} [records]
 * @param {object} [options]
 */
function ContactFormGroup(records, options) {
    BaseFormGroup.call(this, records, options);
    var parent = this.clone();
    var self = this;

    /**
     * @prop {DefaultModalConfirmation}
     */
    this.contactRestoreConfirmation = null;

    /**
     * @prop {boolean}
     */
    this.manageVisitorsAllowed = null;

    /**
     * @inheritDoc
     */
    this.formParams = [{
        name: 'contactSaveForm',
        className: ContactSaveForm
    }, {
        name: 'contactGroupSaveForm',
        className: ContactGroupSaveForm
    }, {
        name: 'addressCreateForm',
        className: AddressSaveForm
    }, {
        name: 'addressUpdateForm',
        className: AddressSaveForm
    }];

    /**
     * @inheritDoc
     */
    this.initDefaults = function() {
        parent.initDefaults.call(this);

        return this.extendDefaultOptions({
            activeFormName: 'contactSaveForm',
            contactSaveForm: {
                data: {
                    manageVisitorsAllowed: this.manageVisitorsAllowed
                },
                hiddenFields: [
                    'contact[group_id]'
                ],
                contactGroupField: {
                    createNew: true,
                    createNewOptions: {
                        attributes: {
                            'class': 'switchTo-contactGroupSaveForm',
                            'data-name': 'contact[group_id]'
                        }
                    },
                    actionButtons: [{
                        type: 'update',
                        className: 'switchTo-contactGroupSaveForm'
                    }]
                },
                billToField: {
                    autocompleteParams: {
                        address_ids: []
                    },
                    createNew: true,
                    createNewOptions: {
                        attributes: {
                            'class': 'switchTo-addressCreateForm billTo',
                            'data-name': 'contact[billing_address_id]'
                        }
                    },
                    actionButtons: [{
                        type: 'update',
                        className: 'switchTo-addressUpdateForm'
                    }]
                },
                soldToField: {
                    autocompleteParams: {
                        address_ids: []
                    },
                    createNew: true,
                    createNewOptions: {
                        attributes: {
                            'class': 'switchTo-addressCreateForm soldTo',
                            'data-name': 'contact[selling_address_id]'
                        }
                    },
                    actionButtons: [{
                        type: 'update',
                        className: 'switchTo-addressUpdateForm'
                    }]
                },
                shipToField: {
                    autocompleteParams: {
                        address_ids: []
                    },
                    createNew: true,
                    createNewOptions: {
                        attributes: {
                            'class': 'switchTo-addressCreateForm shipTo',
                            'data-name': 'contact[shipping_address_id]'
                        }
                    },
                    actionButtons: [{
                        type: 'update',
                        className: 'switchTo-addressUpdateForm'
                    }]
                },
                afterSubmit: function(response) {
                    self.afterContactSaveFormSubmit(response);
                },
                onCheckEmailSuccess: function(response) {
                    self.onCheckEmailSuccessFormSubmit(response);
                },
                onContactGroupFieldChange: function(contactGroup) {
                    self.onContactGroupFieldChange(contactGroup);
                }
            },
            contactGroupSaveForm: {
                switchButtons: [{
                    targetFormName: 'contactSaveForm',
                    label: Renderer.Back('Back', 'left')
                }],
                afterSubmit: function(response) {
                    self.afterContactGroupSaveFormSubmit(response);
                }
            },
            addressCreateForm: {
                switchButtons: [{
                    targetFormName: 'contactSaveForm',
                    label: Renderer.Back('Back', 'left')
                }],
                afterSubmit: function(response, formEl, triggerBtn) {
                    self.afterAddressCreateFormSubmit(
                        response,
                        formEl,
                        triggerBtn
                    );
                }
            },
            addressUpdateForm: {
                actionType: 'update',
                switchButtons: [{
                    targetFormName: 'contactSaveForm',
                    label: Renderer.Back('Back', 'left')
                }],
                afterSubmit: function(response, formEl, triggerBtn) {
                    self.afterAddressUpdateFormSubmit(
                        response,
                        formEl,
                        triggerBtn
                    );
                }
            },
            afterSubmit: undefined
        });
    };

    /**
     * @inheritDoc
     */
    this.init = function() {
        parent.init.call(this);

        var contact = this.records.contactSaveForm;

        if (_.isObject(contact) && contact.group_id > 0) {
            this.updateAddressCreateFormServerParams(contact.group_id);
        }

        return this;
    };

    /**
     * Check email success form submit event handler.
     *
     * @param {object} response
     */
    this.onCheckEmailSuccessFormSubmit = function(response) {
        if (_.isObject(response.contact) && 1 == response.contact.deleted) {
            this.createRestoreCofirmation(response);

            return;
        }

        this.setFormsDefaultRecords(response);
    };

    /**
     * After contact save form submit event handler.
     *
     * @param  {object}            response
     * @return {boolean|undefined}
     */
    this.afterContactSaveFormSubmit = function(response) {
        if (_.isFunction(this.options.afterSubmit) &&
            false === this.options.afterSubmit(response)
        ) {
            return false;
        }

        return undefined;
    };

    /**
     * After contact group save form submit event handler.
     *
     * @param  {object}  response
     * @return {boolean}
     */
    this.afterContactGroupSaveFormSubmit = function(response) {
        var contactGroup = response.contact_group;

        this.setContactGroup(contactGroup);

        this.reload('contactSaveForm');

        return false;
    };

    /**
     * After address create form submit event handler.
     *
     * @param  {object}  response
     * @return {boolean}
     */
    this.afterAddressCreateFormSubmit = function(response) {
        var contactSaveForm = this.forms.contactSaveForm;
        var address = response.address;

        contactSaveForm.options.billToField.autocompleteParams.address_ids.push(address.id);
        contactSaveForm.options.soldToField.autocompleteParams.address_ids.push(address.id);
        contactSaveForm.options.shipToField.autocompleteParams.address_ids.push(address.id);

        if (this.triggerBtn.hasClass('billTo')) {
            contactSaveForm.options.billToField.selectedRecords = [address];
        } else if (this.triggerBtn.hasClass('soldTo')) {
            contactSaveForm.options.soldToField.selectedRecords = [address];
        } else if (this.triggerBtn.hasClass('shipTo')) {
            contactSaveForm.options.shipToField.selectedRecords = [address];
        }

        this.reload('contactSaveForm');

        this.forms.addressCreateForm.resetState();

        return false;
    };

    /**
     * After address update form submit event handler.
     *
     * @param  {object}  response
     * @return {boolean}
     */
    this.afterAddressUpdateFormSubmit = function(response) {
        var contactSaveForm = this.forms.contactSaveForm;
        var address = response.address;

        var fields = ['billToField', 'soldToField', 'shipToField'];

        _.each(contactSaveForm.getAddressFieldNames(), function(addressField) {
            var selectedRecords = contactSaveForm.options[addressField].selectedRecords;

            if (selectedRecords.length > 0 &&
                address.id == selectedRecords[0].id
            ) {
                contactSaveForm.options[addressField].selectedRecords = [address];
            }
        }, this);

        this.reload('contactSaveForm');

        this.forms.addressUpdateForm.resetState();

        return false;
    };

    /**
     * @inheritDoc
     */
    this.onSwitchToFormBtnClick = function(formName, btn) {
        switch (formName) {
            case 'addressUpdateForm':
                this.onSwitchToAddressUpdateFormBtnClick(btn);
                break;
            default:
                parent.onSwitchToFormBtnClick.call(this, formName, btn);
        }
    };

    /**
     * Switch to address update form button click event handler.
     *
     * @param {DOMElement} btn
     */
    this.onSwitchToAddressUpdateFormBtnClick = function(btn) {
        var contactSaveForm = this.forms.contactSaveForm;
        var select = btn.closest('.modal-form-group').find('select');
        var addressField = null;

        if (select.hasClass('billToSelect')) {
            addressField = contactSaveForm.billToField;
        } else if (select.hasClass('soldToSelect')) {
            addressField = contactSaveForm.soldToField;
        } else if (select.hasClass('shipToSelect')) {
            addressField = contactSaveForm.shipToField;
        }

        if (!addressField) {
            return;
        }

        var address = addressField.getRecord();

        this.forms.addressUpdateForm.setRecord(address);

        this.reload('addressUpdateForm', btn);

        return this;
    };

    /**
     * Contact group field change event handler.
     *
     * @param {object} contactGroup
     */
    this.onContactGroupFieldChange = function(contactGroup) {
        this.updateAddressCreateFormServerParams(contactGroup.id);
    };

    /**
     * Create restore deleted contact restore confirmation.
     *
     * @param  {object}           response
     * @return {ContactFormGroup}
     */
    this.createRestoreCofirmation = function(response) {
        this.contactRestoreConfirmation = new DefaultModalConfirmation({
            messageType: 'warning',
            message: this.translator.get('restore_deleted_contact_confirmation_message', {
                email: response.contact.email
            }),
            onConfirm: function() {
                self.restoreDeletedContact(response);
            },
            onCancel: function(){
                self.reload('contactSaveForm');
            }
        }).create();

        return this;
    };

    /**
     * Restore deleted contact event handler.
     *
     * @param  {object}           response
     * @return {ContactFormGroup}
     */
    this.restoreDeletedContact = function(response) {
        this.contactRestoreConfirmation.destroy();

        this.forms.contactSaveForm.extendOptions({
            serverParams: {
                contact: {
                    deleted: 0
                }
            }
        });

        return this.setFormsDefaultRecords(response);
    };

    /**
     * Set forms default records.
     *
     * @param  {object}           response
     * @return {ContactFormGroup}
     */
    this.setFormsDefaultRecords = function(response) {
        var contactSaveForm = this.forms.contactSaveForm;
        var contactGroupSaveForm = this.forms.contactGroupSaveForm;

        var contact = response.contact;
        var remoteContact = response.remote_contact;

        var email = contactSaveForm.getInputEmail();

        var suggestedContactGroup = response.suggested_contact_group;

        contactSaveForm.notifier.clear();

        if (_.isObject(contact)) {
            contactSaveForm.setRecord(contact.extend({
                email: email
            }));

            if (contact.group_id > 0) {
                // Reload contact save form with all fields displayed
                // (contact group already set)
                this.reload('contactSaveForm');

                // Update existing contact
                return this;
            }

            // Contact save form > make contact group select field visible
            contactSaveForm.removeFromArrayOption(
                'hiddenFields',
                'contact[group_id]'
            );

            if (_.isObject(suggestedContactGroup)) {
                this.setContactGroup(suggestedContactGroup);
            }

            // Reload contact save form with contact group select field
            // displayed
            this.reload('contactSaveForm');

            // Update existing contact
            return this;
        } else if (_.isObject(remoteContact)) {
            contactSaveForm.updateDefaultRecord({
                email     : email,
                first_name: remoteContact.first_name,
                last_name : remoteContact.last_name,
                phone     : remoteContact.work_phone,
                mobile    : remoteContact.mobile
            });
        } else {
            contactSaveForm.updateDefaultRecord({
                email: email
            });
        }

        if (_.isObject(suggestedContactGroup)) {
            this.setContactGroup(suggestedContactGroup);

            // Reload contact save form with all fields displayed (contact
            // group set to suggested one)
            this.reload('contactSaveForm');

            // Create new contact (and assign it to suggested contact group)
            return this;
        }

        var remoteContactGroup = response.remote_contact_group;

        if (_.isObject(remoteContactGroup)) {
            contactGroupSaveForm.extendOptions({
                serverParams: {
                    contact_group: {
                        remote_contact_group_id: remoteContactGroup.party_id
                    }
                }
            });

            contactGroupSaveForm.updateDefaultRecord({
                name           : remoteContactGroup.name,
                company_address: remoteContactGroup.address,
                domains        : [EmailHelper.getDomain(email)]
            });

            // Load contact group form with fields prefilled with remote
            // contact group data
            this.reload('contactGroupSaveForm');

            // Create new contact group, then return to create new contact
            // (and assign it to the newly created contact group)
            return this;
        }

        if (response.email_generic) {
            // Contact save form > make contact group select field visible
            contactSaveForm.removeFromArrayOption(
                'hiddenFields',
                'contact[group_id]'
            );

            // Reload contact save form with contact group select field
            // displayed
            this.reload('contactSaveForm');

            // Create new contact with generic email domain (allow user to
            // select existing or create new contact group for contact)
            return this;
        } else {
            contactGroupSaveForm.updateDefaultRecord({
                domains: [EmailHelper.getDomain(email)]
            });
        }

        // Load contact group form (domains field prefilled with entered
        // email domain)
        this.reload('contactGroupSaveForm');

        // Create new contact group, then return to create new contact (and
        // assign it to the newly created contact group)
        return this;
    };

    /**
     * Set contact group.
     *
     * @param  {object}           contactGroup
     * @return {ContactFormGroup}
     */
    this.setContactGroup = function(contactGroup) {
        this.forms.contactSaveForm.setContactGroup(contactGroup);

        return this.updateAddressCreateFormServerParams(contactGroup.id);
    };

    /**
     * Update address create form server params.
     *
     * @param  {int}              contactGroupId
     * @return {ContactFormGroup}
     */
    this.updateAddressCreateFormServerParams = function(contactGroupId) {
        this.forms.addressCreateForm.extendOptions({
            serverParams: {
                attached_to: 'contact_group',
                contact_group: contactGroupId
            }
        });

        return this;
    };

    // Initialize
    this.init();
}

export default ContactFormGroup;
