import BaseService from 'js/base_v3/services/service';
import ValidationError from 'js/errors/validation-error';

const staticSelf = CustomerNumberValidator;

/**
 * @const
 */
staticSelf.DEFAULT_LENGTH = 6;

/**
 * Order - Customer Number Validation Service.
 *
 * @class
 * @extends BaseService
 *
 * @param {object} [options]
 */
function CustomerNumberValidator(options) {
  BaseService.call(this, options);

  /**
   * @param  {string} customerNumber
   * @return {object}
   */
  this.validate = function(customerNumber) {
    if (_.isEmpty(customerNumber)) {
      return;
    }

    if (!/^\d+$/.test(customerNumber) ||
      staticSelf.DEFAULT_LENGTH !== customerNumber.length
    ) {
      throw new ValidationError(this._translator.get(
        'error_invalid_external_customer_number',
      ));
    }
  };

  // Initialize
  this._init();
}

export const customerNumberValidator = new CustomerNumberValidator();

export default CustomerNumberValidator;
