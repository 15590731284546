import BaseEntity from 'js/base_v2/models/entity';

const staticSelf = Address;

/**
 * @const
 */
staticSelf.TYPE_BILL_TO = 'bill_to';

/**
 * @const
 */
staticSelf.TYPE_SHIP_TO = 'ship_to';

/**
 * @const
 */
staticSelf.TYPE_SOLD_TO = 'sold_to';

/**
 * @const
 */
staticSelf.DEFAULT_COUNTRY = {
  id: 1,
  name: 'United States',
  abbreviation: 'US',
};

/**
 * Address.
 *
 * @class
 * @extends BaseEntity
 *
 * @param {?object} data
 */
function Address(data) {
  BaseEntity.call(this, data);

  /**
   * Get state data.
   *
   * @return {?object}
   */
  this.getStateData = function() {
    if (_.isEmpty(this.state)) {
      return null;
    }

    const stateData = {
      name: this.state,
      country_id: this.country_id,
      country_abbreviation: this.country_abbreviation,
      country_name: this.country_name,
    };

    if (!(this.state_id > 0)) {
      return stateData;
    }

    return stateData.extend({
      id: this.state_id,
      name: this.state_name,
      abbreviation: this.state,
    });
  };

  /**
   * Get country data.
   *
   * @return {?object}
   */
  this.getCountryData = function() {
    if (!(this.country_id > 0)) {
      return null;
    }

    return {
      id: this.country_id,
      name: this.country_name,
      abbreviation: this.country_abbreviation,
    };
  };

  // Initialize
  this.init();
}

export default Address;
