var AddressHelper = {};

/**
 * Get address fields.
 *
 * @return {string[]}
 */
AddressHelper.getFields = function() {
    return [
        'id',
        'name',
        'line1',
        'line2',
        'city',
        'state',
        'country',
        'zip',
        'latitude',
        'longitude',
        'region_id'
    ];
};

/**
 * Create address from record.
 *
 * @param  {object} record
 * @param  {string} addressEntityName
 * @return {object}
 */
AddressHelper.createAddressFromRecord = function(record, addressEntityName) {
    var address = {};

    _.each(AddressHelper.getFields(), function(f) {
        var key = addressEntityName + '_' + f;

        if ('undefined' === typeof record[key]) {
            return;
        }

        address[f] = record[key];
    }, this);

    return address;
};

/**
 * Remove address from record.
 *
 * @param  {object} record
 * @param  {string} addressEntityName
 * @return {object}
 */
AddressHelper.removeAddressFromRecord = function(record, addressEntityName) {
    _.each(AddressHelper.getFields(), function(f) {
        var key = addressEntityName + '_' + f;

        if ('undefined' === typeof record[key]) {
            return;
        }

        delete record[key];
    }, this);

    return record;
};

export default AddressHelper;
