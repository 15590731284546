import BaseField from 'js/base_v2/field';
import KeyHelper from 'js/helpers/key-helper';

/**
 * Base Input Field.
 *
 * @class
 * @abstract
 * @extends BaseField
 *
 * @param {DOMElement} fieldCt
 * @param {DOMElement} fieldEl
 * @param {object}     [options]
 */
function BaseInputField(fieldCt, fieldEl, options) {
  BaseField.call(this, fieldCt, fieldEl, options);
  const parent = this.clone();

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      initialValue: undefined,
      placeholder: undefined,
      onChange: undefined,
      onEnterPress: undefined,
    });
  };

  /**
   * @inheritDoc
   */
  this.initFields = function() {
    parent.initFields.call(this);

    if (!_.isUndefined(this.options.initialValue)) {
      this.fieldEl.val(this.options.initialValue);
    }

    if (!_.isUndefined(this.options.placeholder)) {
      this.fieldEl.prop('placeholder', this.options.placeholder);
    }

    return this;
  };

  /**
   * @inheritDoc
   */
  this.registerEventListeners = function() {
    parent.registerEventListeners.call(this);

    this.fieldEl.on('change', () => {
      this.onChange();
    });

    this.fieldEl.on('keypress', (event) => {
      if (KeyHelper.KEY_CODE_ENTER === event.which) {
        this.onEnterPress();
      }
    });

    return this;
  };

  /**
   * @inheritDoc
   */
  this.onChange = function() {
    if (_.isFunction(this.options.onChange)) {
      this.options.onChange();
    }
  };

  /**
   * @inheritDoc
   */
  this.onEnterPress = function() {
    if (_.isFunction(this.options.onEnterPress)) {
      this.options.onEnterPress();
    }
  };
}

export default BaseInputField;
