/**
 * Validation Error.
 *
 * @class
 * @extends Error
 */
class ValidationError extends Error {
  /**
   * @inheritDoc
   */
  constructor(message) {
    super(message);

    this.name = 'ValidationError';
  }
}

export default ValidationError;
